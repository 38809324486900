<template>
  <div>
    <div class="panel panel-default mb-3">
      <div class="panel-heading" :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
        <h5 class="car-title mb-0">
          #{{ car.id }}
          <strong class="margin-l-5" style="position: absolute;">
            {{ car.title }}
          </strong>

          <router-link v-if="[1, 2, 4, 6, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id"
                       :to="{ name: 'car.edit', params: {carId: car.id }}"
                       class="btn btn-primary pull-right"
                       style="font-size: 1rem; margin-left: 6px; position: relative; z-index: 2;"
                       title="Редактировать"
                       type="button">
            <i class="fa fa-edit"></i>
          </router-link>
        </h5>
        <span class="font-medium font-small mt-0">
          <span v-if="car.owner_type === 'client'" class="label label-primary font-medium">
            Клиент
          </span>
          <span v-if="car.owner_type === 'company'" class="label label-primary font-medium">
            Компания
          </span>
          <span v-if="car.owner_type === 'exchange'" class="label label-primary font-medium">
            Обмен/выкуп
          </span>
        </span>
        <span class="label label-success font-medium font-small-xs" style="padding: 2px 3px; margin-left: 5px;">
          {{ car.location === "owner" ? "У владельца" : "В салоне" }}
        </span>
        <span v-if="car.contract_type === 'free'"
              class="label label-info font-medium font-small-xs"
              style="padding: 2px 3px; margin-left: 5px;">
          Бесплатно
        </span>
<!--        <span v-if="car.contract_type === 'fee'"-->
<!--              class="label label-info font-medium font-small-xs"-->
<!--              style="padding: 2px 3px; margin-left: 5px;">-->
<!--          Абонплата-->
<!--        </span>-->
        <span v-if="car.contract_type === 'compensation'"
              class="label label-info font-medium font-small-xs"
              style="padding: 2px 3px; margin-left: 5px;">
          Компенсация
        </span>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-12 col-lg-5">
            <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                 style="min-height: 100px;">
            <img :alt="car.title" class="img-fluid" src="../../assets/img/no-car-photo-240-140.png" v-else>

            <ul class="list-group list-group-unbordered">
              <li class="list-group-item no-border pt-0 pb-1">
                <strong class="font-scale-1">{{ car.price }} $</strong>
                <small v-if="car.old_price && car.is_top_price"
                       class="font-medium text-red text-muted margin-l-5 font-small">
                  {{ car.old_price }}$
                </small>
              </li>
              <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                  v-if="[1, 2, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id) || car.creator.id !== $store.getters['auth/authUser'].id">
                {{ car.created_date }}
                <small>
                  <br/>
                  Ответственный <strong>{{ car.creator.name }}</strong>
                </small>
              </li>
              <li class="list-group-item no-border medium-line-height text-muted font-small pt-0"
                  v-if="car.editor">
                {{ car.updated_date }}
                <small>
                  <br/>
                  Обновил <strong>{{ car.editor.name }}</strong>
                </small>
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-7">
            <ul class="list-group list-group-unbordered">
              <li class="list-group-item pb-1">
                <strong>{{ car.branch.title }}</strong>
                <small v-if="car.branch.address">
                  ({{ car.branch.address }})
                </small>
                <small style="letter-spacing: -.035rem;">
                  <strong v-if="car.specification.engine_title" class="text-black font-small">
                    <span class="font-light">/</span> {{ car.specification.engine_title }}
                  </strong>
                  / {{ car.specification.fuel.title }}
                  / {{ car.specification.gearbox.title }}
                  / {{ car.specification.race }} тыс. км.
                  / {{ car.specification.transmission.title }} привод
                  / {{ car.specification.color.title }}
                  <span v-if="car.origin">/ {{ car.origin.title }}</span>
                  / <strong class="text-black font-small">{{ car.year }}</strong> г.
                </small>
              </li>
              <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0; text-align: right;">
                <clipboard-block :data="car.specification.reg_number" custom_class="btn-default"/>
                <span class="label label-primary margin-r-5">
                  <strong style="font-size: 15px; letter-spacing: .03rem;">
                    {{ car.specification.reg_number }}
                  </strong>
                </span>

                <span class="label label-primary margin-l-5">
                  <strong style="font-size: 14px; letter-spacing: .001rem;">
                    {{ car.specification.vin_code }}
                  </strong>
                </span>
                <clipboard-block :data="car.specification.vin_code" custom_class="btn-default"/>
              </li>
              <li class="list-group-item no-border font-small"
                  style="text-align: right;">
                <i class="fa fa-eye text-muted font-light" style="margin-right: 2px;"/> {{ car.views }}
              </li>
            </ul>
          </div>
          <div v-if="car.sold"
               class="col-12"
               :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
            Продано за
            <strong class="font-scale-1">
              {{ car.sold.sum }}$
            </strong>
            владельцу
            <strong class="font-scale-1">
              {{ car.sold.owner_sum }}$
            </strong>
            комиссия
            <strong class="font-scale-1">
              {{ (car.sold.sum - car.sold.owner_sum) }}$
            </strong>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-lg-12 pt-2">
            <button v-if="($store.getters['auth/authUser'].role.id === 2 && $store.getters['auth/authUser'].id !== car.creator.id) || ([1, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id))"
                    class="btn btn-danger pull-left full-height"
                    title="Удалить" type="button"
                    v-on:click="removeCar(car.id)">
              <i class="fa fa-trash"></i>
            </button>
            <button v-if="[1, 2, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id"
                    class="btn pull-left full-height margin-l-5"
                    :class="car.is_disabled ? 'btn-success' : 'btn-default'"
                    :title="car.is_disabled ? 'Показать' : 'Скрыть' + ' авто на сайте'"
                    type="button" v-on:click="toggleShowCar(car)">
              <i class="fas" :class="car.is_disabled ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
            </button>

            <router-link :to="{ name: 'car.details', params: { carId: car.id }}"
                         class="btn btn-success pull-right margin-l-5"
                         title="Детали"
                         type="button">
              <i class="fa fa-info-circle"></i>
            </router-link>

            <router-link :to="{ name: 'car.details', params: { carId: car.id, currentTab: 'Акты осмотра' }}"
                         class="btn btn-info pull-right margin-l-5"
                         title="Акты осмотра"
                         type="button">
              <i class="fa fa-screwdriver-wrench"></i>
            </router-link>

            <router-link v-if="car.seo && [1, 4, 5, 7, 8, 9, 13].includes($store.getters['auth/authUser'].role.id)"
                         :to="{ name: 'seo_item.edit', params: {seoItemId: car.seo.id }}"
                         class="btn pull-right"
                         v-bind:class="car.seo.title ? 'btn-success' : 'btn-default'"
                         style="font-size: 1rem; margin-left: 6px;"
                         title="SEO-данные"
                         type="button">
              <strong>SEO</strong>
            </router-link>

            <a v-if="!car.is_disabled" :href="$clientAppUrl + '/car/' + car.slug" :title="car.slug"
               style="font-size: 1rem; margin-left: 6px;"
               class="btn btn-info pull-right" target="_blank">
              <i class="fas fa-external-link-square-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
import ClipboardBlock from './../../components/blocks/clipboard-block.vue';
import 'vue-nav-tabs/themes/vue-tabs.css';

Vue.use(VuejsDialog);

export default {
  name: 'car-item',

  components: {
    ClipboardBlock,
  },

  props: {
    car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    async showCarFinanceOperationsModal(carId) {
      this.$modal.show('car_finance_operations_modal_' + carId);
    },
    toggleShowCar(car) {
      this.$dialog.confirm(
          'Вы уверены что хотите ' + (!car.is_disabled ? 'скрыть' : 'показать') + ' автомобиль?',
          this.$dialogOptions
      ).then(() => {
        API.apiClient.put('/car/' + car.id + '/toggle_show').then(() => {
          showSuccess();
          this.EventBus.$emit('card-filter-changed');
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
    removeCar(carId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/cars/' + carId).then(() => {
          this.EventBus.$emit('card-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
